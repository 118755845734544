import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobForm3 from "../components/job-application-form3"

const AutomationEngineer = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Cloud Engineer" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Cloud Engineer</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                <h3>Contact us and send us your CV for review.</h3>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>

            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Job Title</h3>
                  <div className="aut-div">
                    <p>Cloud Engineer</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Department</h3>
                  <div className="aut-div">
                    <p>Cloud Team</p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                      Must be a US citizen. Flexible - working from home, office or on customer sites
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Job Description</h3>
                  <div className="aut-div">
                    <p>
                      Install and configure Morpheus Data appliance on cloud
                      host(s). Work with Morpheus Data tool to configure apps
                      and provision instances typically in a cloud environment.
                      Customize Morpheus for customer needs. Build tasks,
                      scripts, workflows, jobs, etc to automate processes.
                      Manage users and groups through tenancy and RBAC. Remote
                      work. Must be a US citizen.{" "}
                    </p>
                  </div>
    
                <div>
                  <h3>Preferred Skills:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • Morpheus Data experience or similar Cloud Management
                        Platform (CMP){" "}
                      </li>

                      <li>• Heavy AWS/Azure/GCP experience </li>
                      <li>• Terraform and Ansible skills </li>
                      <li>• Python, Bash, and Powershell experience</li>
                    </ul>
                  </div>
                </div>

                <div>
                  <br />
                  <h3>Required Skills:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        {" "}
                        • Some experience with deploying/managing instances in a
                        cloud{" "}
                      </li>
                      <li> • Some programming experience </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <br />
                  <h3>About Metsi</h3>
                  <div className="aut-div">
                    <p>
                      Metsi Technologies Inc., based in Nashville, TN, is a
                      digital systems integrator that accelerates digital
                      transformation for the IT enterprise through continuous
                      digital innovation, digital applications, hybrid
                      multicloud adoption, microservices, software defined, and
                      Anything-as-a-Service (XaaS). We create the secure IT
                      flexibility that meets business demand.
                      <br />
                      Metsi’s goal is to deliver fast and comprehensive
                      technology modernization, based on best of breed
                      technologies, both COTS and open source; compelling
                      consulting and thought leadership; and stellar engineering
                      delivery. We believe in excellence, integrity, and
                      comradery.
                      <br /> Metsi is an OEM services partner of Cisco,
                      ServiceNow, RedHat, Microsoft, VMware, AppDynamics,
                      Dynatrace, Morpheus, CloudBolt, Spectro Cloud, NetBrain,
                      and PMG . Metsi also holds VAR services partnerships with
                      WWT, Technologent, Trace3, Sirius Computer Solutions,
                      CyKor, and Synnex.{" "}
                    </p>
                    <h3>Apply Now:</h3>
                  </div>
                </div>
                </div>

                <JobForm3 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AutomationEngineer
